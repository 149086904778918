import React from "react";

const CareerBanner = ({ title, image, description, btn }) => {
  return (
    <div
      className="mx-auto px-4 lg:h-full md:bg-no-repeat h-96 bg-cover career-banner"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="container md:max-w-c mx-auto md:min-h-screen flex items-center justify-center">
        <div className="z-10">
          <div className="flex justify-center md:items-start items-center md:justify-start flex-col">
            <h1 className="uppercase mt-10 md:mt-0 text-center md:text-left text-2xl md:w-5/6 md:text-4xl text-white font-normal">
              {title ? title : ""}
            </h1>
            <div className="w-20 md:w-80 bg-orange-500 h-0.5 my-5"></div>
            <div className="md:w-3/6">
              <p className="text-white text-xl md:text-left text-center">
                {description ? description : ""}
              </p>
            </div>
            {/* <a
              href="#"
              className="p-4 w-fit mt-4 md:mt-10  border-1 border-orange-400 text-orange-500 bg-transparent hover:text-white hover:bg-orange-500 ease-linear duration-100"
            >
              {btn}
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerBanner;
