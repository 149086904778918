import React, { useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import { useTranslation } from "react-i18next";
import { why } from "../data/career";
import { responsibility } from "../data/career";
import "../css/career.css";
import CareerBanner from "../components/CareerBanner";
import bg from "../images/home_banner.jpeg";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Career = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Tuyển dụng";
  }, []);

  return (
    <section className="bg-white border-b">
      <div className="mx-auto">
        <CareerBanner
          title={t("career.title")}
          image={bg}
          description={t("career.description")}
          btn={t("career.apply_now")}
        />
      </div>
      <div className="mx-auto mt-10 lg:mt-32 p-4">
        <div className="container mx-auto">
          <h1 className="w-full my-2 text-4xl uppercase font-medium  leading-tight text-center text-gray-900">
            {t("career.why")}
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
        </div>
      </div>
      <div className="md:px-0 px-10">
        {why.map((item, index) => (
          <div
            className={classNames(
              parseInt(index) % 2 === 1
                ? "md:bg-orange-500 bg-white"
                : "bg-white ",
              "mx-auto "
            )}
            key={index}
          >
            <div className=" mx-auto">
              <div
                className={classNames(
                  parseInt(index) % 2 === 0
                    ? "sm:flex-row"
                    : "sm:flex-row-reverse ",
                  "flex flex-col flex-wrap "
                )}
              >
                <div className="w-full sm:w-1/2">
                  <img
                    src={require(`../images/become_part_of_our_team/${item.image}`)}
                    className="md:rounded-none rounded-lg"
										alt=""
                  />
                </div>
                <div className="w-full md:w-1/2 md:px-6 mt-6 align-middle">
                  <div className="align-middle">
                    <h3
                      className={classNames(
                        parseInt(index) % 2 === 1
                          ? "text-white "
                          : " text-gray-900",
                        "text-3xl  font-bold leading-none mb-3"
                      )}
                    >
                      {t(`career.why_list.${index}.title`)}
                    </h3>
                    <p
                      className={classNames(
                        parseInt(index) % 2 === 1
                          ? "text-white"
                          : "text-gray-900",
                        " mb-8"
                      )}
                    >
                      {t(`career.why_list.${index}.description`)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mx-auto py-20 bg-orange-500">
        <div className="max-w-c mx-auto">
          <HeaderComponent
            title={t("career.respon_title")}
            description={t("career.respon_desc")}
          />
          <div className=" flex flex-col items-center justify-between">
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:grid-flow-col gap-10 p-10">
              {responsibility.map((item, index) => (
                <div className="respons " key={index}>
                  <div className="res-box"></div>
                  <div className="px-5 res-content h-96 ">
                    <h2 className="font-bold mb-10 pt-10 text-4xl text-orange-100">
                      {t(`career.responsibility.${index}.name`)}
                    </h2>
                    <p className="text-xl font-small text-orange-100">
                      {" "}
                      {t(`career.responsibility.${index}.description`)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mx-auto m-8">
        <div className="max-w-c mx-auto">
          <HeaderComponent
            title={t("career.benefit_title")}
            description={t("career.benefit_desc")}
          />
        </div>
      </div> */}
      {/* <div className="mx-auto my-8">
        <div className="max-w-c mx-auto">
          <div className="flex items-center justify-center">
            <a
              href="#"
              className="p-4 border-1 border-orange-400 text-orange-500 bg-transparent hover:text-white hover:bg-orange-500 ease-linear duration-100"
            >
              {t("career.apply_now")}
            </a>
          </div>
        </div>
      </div> */}
      <div className="mx-auto py-20">
        <div className="max-w-c mx-auto">
          <HeaderComponent title={t("career.alljob")} description="" />
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-10">
            <div className="text-center  p-10 border-1 border-orange-500  shadow-lg shadow-orange-300/50">
              <p className="uppercase text-orange-500 text-xl pb-5">
                Front-End developer
              </p>
              <div className="">
                <p>{t(`career.FE`)}</p>
              </div>
              <div className="mt-10">
                <Link to={"/front-end"} className="p-5 border-1 border-orange-500 hover:bg-orange-500 text-orange-500 hover:text-white ">
                  Xem chi tiết
                </Link>
              </div>
            </div>
            <div className="text-center  p-10 border-1 border-orange-500  shadow-lg shadow-orange-300/50">
              <p className="uppercase text-orange-500 text-xl pb-5">
                Back-End developer
              </p>
              <div className="">
                <p>{t(`career.BE`)}</p>
              </div>
              <div className="mt-10">
                <Link to={"/back-end"} className="p-5 border-1 border-orange-500 hover:bg-orange-500 text-orange-500 hover:text-white ">
                  Xem chi tiết
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Career;
