import React from "react";
import { useTranslation } from "react-i18next";
import "../css/form.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Form = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center bg-form-bg bg-contain">
      <div className="container max-w-3xl p-20">
        <form>
          <h3 className="mb-4 text-white text-xl">{t(`form.title`)}</h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <AnimationOnScroll animateIn="animate__rotateInDownLeft" className="w-full mb-4">
              <input
                type="text"
                id="text"
                className="w-full block p-2.5  text-sm bg-transparent placeholder-orange-300 text-white border-orange-300 border-1  outline-none mr-4"
                placeholder={t(`form.h1`)}
              />
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__rotateInDownRight"  className="w-full mb-4">
              <input
                type="email"
                id="email"
                className="block p-2.5 w-full text-sm text-white bg-transparent placeholder-orange-300  border-orange-300 border-1  outline-none"
                placeholder={t(`form.h2`)}
              />
            </AnimationOnScroll>
          </div>
          <div>
            <AnimationOnScroll animateIn="animate__zoomIn">
               
            <textarea
              id="message"
              rows="4"
              className="block p-2.5 min-h-40 w-full h-40 text-sm text-white bg-transparent placeholder-orange-300  border-orange-300 border-1 outline-none"
              placeholder={t(`form.h3`)}
            ></textarea>
                        </AnimationOnScroll>

          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="text-white bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 font-medium text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:focus:ring-yellow-900"
            >
              {t(`form.btn`)}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
