import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import vi from "./data/translate/vi/vi.json";
import en from "./data/translate/en/en.json";

const resources = {
  vi: {
    translation: vi,
  },
  en: {
    translation: en,
  },
};
const fallbackLng = ["vi"];
const availableLanguages = ["vi", "en"];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng:"vi",
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
