import React from "react";

const HeaderComponent = ({ title, description }) => {
  return (
    <div className="flex justify-center">
      <div className="xl:w-9/12 lg:w-10/12  p-6 text-center">
          <h3 className="uppercase font-medium  text-gray-900 text-4xl pb-3">
            {title}
          </h3>
          <p className="text-gray-800 mb-4 lg:mb-8 text-center">{description}</p>

      </div>
    </div>
  );
};

export default HeaderComponent;
