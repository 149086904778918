

export const services = [
  {
    name: "Social Listening",
    image: "social.svg",
    href: "social-listening"
  },
  {
    name:"Giải pháp doanh nghiệp",
    image:"enterprise_solutions.svg",
    href:"enterprise-solution"
  },
  {
    name: "Phát triển ứng dụng web",
    image: "web_development.svg",
    href:"web-development"
  },
  {
    name: "Phát triển ứng dụng mobile",
    image: "mobile_development.svg",
    href:"mobile-development"
  },
  {
    name: "Dịch vụ tái cấu trúc/di chuyển",
    image: "server.svg",
    href: "reengineering-and-migration"
  },
  {
    name:"UI/UX Design",
    image:"ui_ux.svg",
    href:"uiux"
  },{
    name:"QA",
    image:"qa.svg",
    href:"qa"
  }
];


