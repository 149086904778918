import React from "react";
import HeaderComponent from "./HeaderComponent";
import { believe } from "../data/ourValue";
import { coreValue } from "../data/ourValue";
import { useTranslation } from "react-i18next";

const OurValue = () => {
  const {t} = useTranslation()
  return (
    <div className="mx-auto">
      <div className="container lg:max-w-c mx-auto m-8">
        <HeaderComponent title={t('about.title')} />
        <div className="grid grid-cols-1 ">
          {believe.map((item, index) => (
            <div className="p-6 text-center" key={index}>
              <div className="mx-auto xl:w-5/6">
                <h3 className="uppercase font-bold  text-gray-800 text-2xl pb-3">
                  {t(`about.believe.${index}.title`)}
                </h3>
              </div>
              <div className="mx-auto xl:w-3/4">
                <p className="text-gray-600 mb-8 text-center">
                  {t(`about.believe.${index}.description`)}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5">
          {coreValue.map((item, index) => (
            <div className="bg-gray-50 mx-2 mb-10" key={index}>
              <div className="bg-orange-400">
                <h3 className="uppercase font-medium text-center text-white text-xl p-3">
                  {t(`about.coreValue.${index}.title`)}
                </h3>
              </div>
              <div className="px-4 pt-4">
                <p className="text-gray-500 mb-8">
                  {t(`about.coreValue.${index}.description`)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurValue;
