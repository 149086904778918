import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import image from "../images/industries/industries.jpeg";
const Industries = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Indutries";
  }, []);

  const item = [
    {
      name: "Thương mại điện tử",
      icon: "e-commere.svg",
    },
    {
      name: "Tài chính",
      icon: "finance.svg",
    },
    {
      name: "Nhân sự",
      icon: "hr.svg",
    },
    {
      name: "Bảo hiểm",
      icon: "insurance.svg",
    },
  ];

  return (
    <section>
      <div
        className="mx-auto lg:h-full bg-no-repeat bg-cover home-banner"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="container p-10 lg:max-w-c mx-auto lg:min-h-screen flex items-center justify-center">
          <div className="z-10">
            <h1 className="uppercase text-4xl font-bold w-9/12   lg:text-6xl text-white ">
              {t(`industries.title`)}
            </h1>
            <div className="w-20 lg:w-80 bg-orange-500 h-0.5 my-5"></div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-c py-20 flex justify-center items-center">
        <div className="">
          <div className="text-center">
            <h1 className="text-4xl text-orange-500 font-bold uppercase">
              {t(`industries.h1`)}
            </h1>
            <p className="px-12 text-xl uppercase mb-10 mt-5">
              {t(`industries.h2`)}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-10">
            {item.map((item, index) => (
              <div key={index} className="flex flex-col text-center">
                <h1 className="text-2xl font-bold text-gray-900 pb-10">
                  {t(`industries.item.${index}.name`)}
                </h1>
                <img
                  src={require(`../images/industries/item/${item.icon}`)}
                  className="h-20 sepia"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Industries;
