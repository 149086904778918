import React, { useEffect } from "react";
import bg from "../images/home_banner.jpeg";
import "../css/home.css";
import HomeServices from "../components/HomeServices";
import HomeBanner from "../components/HomeBanner";
import Form from "../components/Form";
import HeaderComponent from "../components/HeaderComponent";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Home";
  }, []);

  return (
    <section className="bg-white border-b">
      <HomeBanner
        title={t("home.title")}
        image={bg}
        description={t("home.description")}
      />
      <div className="mt-10">
        <HeaderComponent
          title={t("home.mission_title")}
          description={t("home.mission_desc")}
        />
      </div>
      <div className=""></div>
      <div className="bg-orange-50 py-20">
        <HomeServices />
      </div>
      <Form />
    </section>
  );
};

export default Home;
