import React from "react";
import Logo from "../images/LOGO-Credito.png";
import { contacts } from "../data/contact";
import { services } from "../data/services";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const navigator = [
  { name: "Trang chủ", href: "/" },
  { name: "Dịch vụ", href: "/services" },
//  { name: "Tuyển dụng", href: "/career" },
  { name: "Nganh", href: "/industries" },
  { name: "Giới thiệu", href: "/about" },
];

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="text-center lg:text-left bg-gray-100 text-gray-600 border-t-1">
      <div className="max-w-c container mx-auto">
        <div className=" py-10 text-center md:text-left">
          <div className="grid grid-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
            <div className="">
              <h6
                className="
            uppercase
            font-semibold
            mb-4
            flex
            items-center
            justify-center
            md:justify-start
            text-orange-500
          "
              >
                <img src={Logo} className="w-10 mr-3" alt="Credito Logo" />
                Credito
              </h6>
              <p>{t(`contact.description`)}</p>
            </div>
            <div className="">
              <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start"></h6>
              {navigator.map((item, index) => (
                <p className="mb-4" key={index}>
                  <Link
                    to={item.href}
                    className="text-gray-600 hover:text-orange-500"
                  >
                    {t(`navigation.${index}.name`)}
                  </Link>
                </p>
              ))}

              <p className="mb-4">
                <Link
                  to={"/privacy"}
                  className="text-gray-600 hover:text-orange-500"
                >
                  Privacy
                </Link>
              </p>
            </div>

            <div className="">
              <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                {t(`contact.name`)}
              </h6>
              <p className="flex items-start justify-center md:justify-start mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mr-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
                {t(`contact.address`)}
              </p>
              <p className="flex items-center justify-center md:justify-start mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                {contacts.email}
              </p>
              <p className="flex items-center justify-center md:justify-start mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="phone"
                  className="w-4 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                  ></path>
                </svg>
                {contacts.phone}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center p-6  bg-orange-500 text-white font-medium">
        <span>© 2022 Copyright: </span>
        <a
          className="text-white font-semibold"
          href="https://tailwind-elements.com/"
        >
          Credito
        </a>
      </div>
    </footer>
  );
};

export default Footer;
