import React from "react";
import { useTranslation } from "react-i18next";
import LeSyDung from "../images/team/LeSyDung.jpeg";
import LeSyQuoc from "../images/team/LeSyQuoc.jpeg";
/* import NguyenTuanQuang from "../images/team/NguyenTuanQuang.jpeg"; */
import HeaderComponent from "./HeaderComponent";

const management = [
//  {
//    name: "Lê Sỹ Dũng",
//    position: "Chairman",
//    img: LeSyDung,
//  },
  /* { */
  /*   name: "Nguyễn Tuấn Quang", */
  /*   position: "BOD", */
  /*   img: NguyenTuanQuang, */
  /* }, */
//  {
//    name: "Lê Sỹ Quốc",
//    position: "CEO",
//    img: LeSyQuoc,
//  },

];

const ManagementTeam = () => {
  const {t} = useTranslation()
  return (
    <div className="mx-auto bg-gray-100 py-20">
      <div className="container max-w-c mx-auto m-8">
       <HeaderComponent title={t("ManagementTeam.title")} description={t("ManagementTeam.description")}/>
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2 xl:grid-cols-3 mt-7 team-lead">
          {management.map((item, index) => (
      
            <div
              className="rounded-lg p-2 team-lead-item mx-2 bg-white"
              key={index}

            >
              <div className="">
                <img src={item.img} className="rounded-lg" alt={item.name} />
                <div className="my-7">
                  <p className="font-medium text-xl">{item.name}</p>
                  <p>{item.position}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManagementTeam;
