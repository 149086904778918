export const believe = [
    {
      title: "Người của chúng tôi định nghĩa chúng tôi",
      description:
        "Credito là nơi mà những người tài năng phát triển, tự do sáng tạo và đạt những điều không tưởng. Đây sẽ là một thỏi nam châm thu hút những người thông minh nhất, sáng tạo nhất và năng động nhất.",
    },
    {
      title:
        "Các sản phẩm và dịch vụ chúng tôi tạo ra tạo nên sự khác biệt cho chúng tôi",
      description:
        "Chúng tôi khao khát cải thiện mọi cuộc sống mà chúng tôi tiếp xúc và biến thế giới thành một cộng đồng kết nối hơn nữa thông qua sản phẩm và dịch vụ chúng tôi tạo ra.",
    },
    {
      title: "Tổ chức của chúng tôi sẽ tồn tại lâu hơn chúng tôi",
      description:
        "Chúng tôi cố gắng xây dưng một tổ chức tồn tại qua nhiều thế hệ và phát triển dựa trên những giá trị cốt lõi của chúng tôi.",
    },
  ];
  
export const coreValue = [
    {
      title: "Chúng tôi phục vụ",
      description:
        "Điều khách hàng của chúng tôi coi trong nhất là giá trị của sản phẩm và dịch vụ. Chúng tôi cố gắng đáp ứng các nhu cầu chưa được đáp ứng và phục vụ những người chưa được phục vụ",
    },
    {
      title: "Chúng tôi thích ứng",
      description:
        "Thay đổi nhanh chóng là hằng số duy nhất trong thời đại kỹ thuật của chúng ta. Chúng tôi đón nhận sự thay đổi, tôn vinh nó và luôn cố gắng trở thành một nhà lãnh đạo tư tưởng ảnh hưởng đến nó.",
    },
    {
      title: "Chúng tôi đi nhanh hơn",
      description:
        "Chúng ta đang trong một cuộc chạy đua không ngừng đến thành công. Chúng tôi di chuyển nhanh hơn và khẩn trương hơn mỗi ngày.",
    },
    {
      title: "Chúng tôi cam kết",
      description:
        "Chúng tôi lấy giá trị của các sản phẩm, dịch vụ mà chúng tôi thực hiện để làm mục tiêu làm việc. Trên tất cả, chúng tôi cam kết làm tốt nhất có thể, nhanh nhất có thể.",
    },
    {
      title: "Chúng tôi vẫn khiêm tốn",
      description:
        "Chúng tôi đã đi một chặng đường dài từ sự khiêm tốn của chúng tôi, tuy nhiên chúng tôi không bao giờ đánh mất sự khiêm tốn đó trong việc tìm đến những mục tiêu cao hơn.",
    },
  ];