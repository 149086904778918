import React from "react";
import { useTranslation } from "react-i18next";
import image from "../images/industries/industries.jpeg";

const FrontEnd = () => {
  const { t } = useTranslation();
  return (
    <section className="bg-white border-b">
      <div className="">
        <div
          className="mx-auto lg:h-full bg-no-repeat bg-cover home-banner"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="container p-10 lg:max-w-c mx-auto lg:min-h-screen flex items-center justify-center">
            <div className="z-10">
              <h1 className="uppercase text-4xl font-bold w-9/12   lg:text-6xl text-white ">
                Front-End developer
              </h1>
              <div className="w-20 lg:w-80 bg-orange-500 h-0.5 my-5"></div>
            </div>
          </div>
        </div>
        <div className="max-w-3xl mx-auto py-10 list">
          <h1 className="text-3xl font-bold text-gray-800 mb-3">
            {t(`fontend-developer.h1`)}
          </h1>
          <p>{t(`fontend-developer.p1`)}</p>
          <p>{t(`fontend-developer.p2`)}</p>
          <p>{t(`fontend-developer.p3`)}</p>
          <p>{t(`fontend-developer.p4`)}</p>
          <p>{t(`fontend-developer.p5`)}</p>
          <p>{t(`fontend-developer.p6`)}</p>
        </div>
        <div className="max-w-3xl mx-auto py-10 list">
          <h1 className="text-3xl font-bold text-gray-800 mb-3">
            {" "}
            <p>{t(`fontend-developer.h1`)}</p>
          </h1>
          <p>{t(`fontend-developer.p7`)}</p>
          <p>{t(`fontend-developer.p8`)}</p>
          <p>{t(`fontend-developer.p9`)}</p>
          <p>{t(`fontend-developer.p10`)}</p>
          <p>{t(`fontend-developer.p11`)}</p>
          <p>{t(`fontend-developer.p12`)}</p>
          <p>{t(`fontend-developer.p13`)}</p>
        </div>
        <div className="max-w-3xl mx-auto py-10 list">
          <h1 className="text-3xl font-bold text-gray-800 mb-3">
            {t(`fontend-developer.h1`)}
          </h1>
          <p>{t(`fontend-developer.p14`)}</p>
          <p>{t(`fontend-developer.p15`)}</p>
          <p>{t(`fontend-developer.p16`)}</p>
          <p>{t(`fontend-developer.p17`)}</p>
          <p>{t(`fontend-developer.p18`)}</p>
          <p>{t(`fontend-developer.p19`)}</p>
        </div>
        <div className="max-w-3xl mx-auto py-10 list">
          <h1 className="text-3xl font-bold text-gray-800 mb-3">Apply</h1>
          <p>
            
          {t(`send-cv`)}
            <a href="mailto:hr@credito.asia" className="text-orange-500"> hr@credito.asia</a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default FrontEnd;
