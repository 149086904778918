import React from "react";

const HomeBanner = ({ title, image, description }) => {
  return (
    <div
      className="mx-auto lg:h-full bg-no-repeat bg-cover home-banner"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="container p-10 lg:max-w-c mx-auto lg:min-h-screen flex items-center justify-center">
        <div className="z-10">
          <h1 className="uppercase text-3xl   lg:text-8xl text-white font-normal">
            {title ? title : ""}
          </h1>
          <div className="w-20 lg:w-80 bg-orange-500 h-0.5 my-5"></div>
          <div className="lg:w-3/6">
            <p className="text-white text-xl lg:text-2xl">
              {description ? description : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
