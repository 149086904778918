export const why = [
  {
    title: "Bạn sẽ được tiếp cận với các chương trình đào tạo tốt nhất",
    description:
      "Chúng tôi có lộ trình đào tạo rõ ràng cho nhân viên, để họ có thể phát huy được hết tiềm năng mà họ có.",

    },
  {
    title: "Bạn sẽ có một môi trường làm việc năng động, đầy sự hứng khởi",
    description:
      "Chúng tôi có một môi trường làm việc thân thiện và trẻ trung, không gian thoáng đãng. Hỗ trợ các trang thiết bị cần thiết như máy tính, laptop,... cùng nhiều chế độ phúc lợi khác",

     },
  {
    title: "Bạn sẽ làm việc chăm chỉ nhưng cũng chơi hết mình",
    description:
      "Ngoài những giờ làm việc, chúng tôi còn có những hoạt động thể dục thể thao, vui chơi giải trí khác như đá bóng, ca hát, ...",

    },
];

export const responsibility = [
  {
    "name": "Chương trình tư vấn",
    "description":
      "Chúng tôi thường xuyên cung cấp cho nhân viên của mình cơ hội tham gia các chương trình tư vấn để có được các kỹ năng và kiến thức để thăng tiến trong công việc, nâng cao trình độ và tăng hiệu suất",
  },
  {
    "name": "Kế hoạch phát triển bản thân",
    "description":
      "Các chuyên gia của chúng tôi có kế hoạch phát triển cá nhân có thể nâng cao phẩm chất mà họ đang có. Với một PDP có tổ chức tốt, tất cả các thành viên trong nhóm có thể nâng cao kiến thức và kỹ năng của họ, đồng thời lập kế hoạc nghề nghiệp đúng đắn",
  },
  {
    "name": "Hội thảo trải nghiệm",
    "description":
      "Chúng tôi tổ chức các hội thảo kinh nghiệm như một nỗ lực hợp tác của các chuyên gia muốn chia sẻ kinh nghiệm của họ với người khác. Trong các hội thảo này, chúng tôi tập trung vào các kỹ năng cụ thể và công nghệ trong sáng tạo phát triển phần mềm.",
  },
];