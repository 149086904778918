import React from "react";
import HeaderComponent from "./HeaderComponent";
import { services } from "../data/services";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HomeServices = () => {
	const { t } = useTranslation();
	return (
		<div className="mx-auto">
			<div className="flex items-center justify-center">
				<div className="container max-w-c">
					<HeaderComponent
						title={t("home_service.title")}
						description={t("home_service.description")}
					/>
					<div className="flex justify-center">
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-4">
							{services.map((item, index) => (
								<Link to={item.href}>
									<div className=" flex flex-col p-2 text-center" key={index}>
										<div className=" 2">
											<img
												src={require(`../images/services/${item.image}`)}
												className="hover:scale-105 transition-all ease-in-out delay-100"
												alt=""
											/>
										</div>
										<p className="font-medium text-xl uppercase text-gray-800">
											{t(`home_service.services.${index}.name`)}
										</p>
									</div>
								</Link>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeServices;
