import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Career from "./pages/Career";
import About from "./pages/About";
import Services from "./pages/Services";
import Industries from "./pages/Industries";
import FrontEnd from "./pages/FrontEnd";
import BackEnd from "./pages/BackEnd";
import Privacy from "./pages/Privacy";
import Product from "./pages/Product";
import SocialListening from "./pages/SocialListening";
function App() {
	return (
		<div>
			<Router>
				<Header />
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/career" exact component={Career} />
					<Route path="/about" component={About} />
					<Route path="/services" component={Services} />
					<Route exact path="/solution" component={Product} />
					<Route path="/social-listening" component={SocialListening} />
					<Route path="/industries" component={Industries} />
					<Route path="/front-end" component={FrontEnd} />
					<Route path="/back-end" component={BackEnd} />
					<Route path="/privacy" component={Privacy} />
				</Switch>
				<Footer />
			</Router>
		</div>
	);
}

export default App;
