import React, { useEffect } from "react";
import ManagementTeam from "../components/ManagementTeam";
import OurValue from "../components/OurValue";
import { useTranslation } from "react-i18next";
const About = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "About";
  }, []);
  return (
    <section className="bg-white">
      <OurValue />
      <ManagementTeam />
    </section>
  );
};

export default About;
