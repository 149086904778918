import React, { useEffect, useState } from "react";
import Logo from "../images/LOGO-Credito.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "./LanguageSelect";
import { Menu } from "@material-ui/icons";
import "../css/header.css";
const navigator = [
  { name: "Trang chủ", href: "/" },
  { name: "Dịch vụ", href: "/services" },
  /* { name: "Giải pháp", href: "/solution" }, */
//  { name: "Tuyển dụng", href: "/career" },
  { name: "Nganh", href: "/industries" },
  { name: "Giới thiệu", href: "/about" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const { t } = useTranslation();

  const location = useLocation();
  const [pathName, setPath] = useState("");
  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  return (
    <nav className="min-h-full sticky top-0 z-50 bg-white shadow-md shadow-orange-400">
      <div className="container mx-auto max-w-c">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/">
              <img src={Logo} className="h-16" alt="Credito" />
            </Link>
          </div>
          <div className="h-full md:block">
            <div className="ml-10 hidden lg:flex items-center space-x-4 h-full">
              {navigator.map((item, index) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.href === pathName
                      ? "border-b-4 border-orange-500 text-gray-900"
                      : "text-gray-800",
                    "px-4 py-4  text-base font-medium hover:text-orange-500 ease-linear duration-300 h-full"
                  )}
                >
                  {t(`navigation.${index}.name`)}
                </Link>
              ))}

              <LanguageSelect />
            </div>
            <div className="menu-container lg:hidden">
              <Menu
                onClick={() => setActiveMenu(!activeMenu)}
                className="menu-btn"
              />
              {activeMenu ? (
                <div className="flex lg:hidden flex-col menu bg-white z-100">
                  {navigator.map((item, index) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="p-10  py-3  text-base font-medium hover:text-orange-500 ease-linear duration-300"
                    >
                      {t(`navigation.${index}.name`)}
                    </Link>
                  ))}

                  <LanguageSelect />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
