import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import HomeBanner from "../components/HomeBanner";
import HomeServices from "../components/HomeServices";
import image from "../images/services/service_bg.jpeg";
const Services = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Services";
  }, []);
  const items = [
    {
      name: "Tạo một ứng dụng chưa bao giờ dễ đến thế với sự phục vụ của Credito",
      description:
        "Ưu tiên của chúng tôi luôn là mang đến sản phẩm tới tay khách hàng với thời gian ngắn nhất, chất lượng tốt nhất.",
    },
    {
      name: "Tư vấn công nghệ",
      description:
        "Đội ngũ kỹ thuật của chúng tôi luôn dành thời gian để lấy ý kiến từ khách hàng, tư vấn cho khách hàng những mô hình công nghệ tốt nhất dựa trên những yêu cầu khách hàng đưa ra. Từ đó xây dựng nên một ứng dụng phù hợp với khách hàng",
    },
    {
      name: "Bảo mật thông tin",
      description:
        "Chúng tôi có những người giỏi nhất trong việc bảo mật thông tin, họ áp dụng nhiều phương pháp bảo mật hiện đại nhất để phục vụ việc thông tin từ người dùng được giấu kín,",
    },
  ];
  return (
    <section className="bg-white">
      <HomeBanner
        title={t(`services.title`)}
        description={t(`services.description`)}
        image={image}
      />
      <div className="py-20 bg-orange-50 px-10">
        <div className="mx-auto max-w-c ">
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-5 gap-10">
              <div className="col-span-2 hidden md:block">
                <img src={image} alt="" className="rounded-lg " />
              </div>
              <div className="col-span-1 md:col-span-3">
                <div className="">
                  <h1 className="text-6xl text-gray-800 font-bold pb-4 ">
                    {t(`services.h1`)}
                  </h1>
                  <div className="mt-10">
                    {items.map((item, index) => (
                      <div className="mt-10" key={index}>
                        <h1 className="font-medium text-orange-400 pb-5 text-3xl">
                          {t(`services.items.${index}.name`)}
                        </h1>
                        <p className="text-gray-800 font-medium text-xl">
                          {t(`services.items.${index}.description`)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-20">
        <HomeServices />
      </div>
      <div className="py-20 bg-orange-50">
        <div className="max-w-c mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div className="">
              <h1 className="text-5xl font-bold text-gray-800">
                {t(`services.b1`)}
              </h1>
            </div>
            <div className="">
              <h1 className="text-3xl text-gray-500 font-medium pb-10">
                {t(`services.b2`)}
              </h1>
              <h1 className="pb-10">{t(`services.b3`)}</h1>
              <div>
                <button className="px-3 py-3 rounded-md bg-orange-500 text-white hover:bg-orange-400">
                  {t(`services.btn`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
