import { Icon2, Icon1, Icon3, Icon4, Icon5, Icon6 } from "../images/products";
import "./social.less";
const vsDn = {
	list: [
		"Phân tích ngành hàng, so sánh các thương hiệu trong 1 ngành hàng",
		"Phân tích sức khỏe thương hiệu",
		"Phân tích chiến dịch marketing",
		"Phân tích xu hướng",
		"Phân tích người ảnh hưởng",
		"Chăm sóc khách hàng, nghiên cứu thị trường",
		"Cảnh báo khủng hoảng truyền thông",
	],
	title: "ĐỐI VỚI DOANH NGHIỆP",
};
const vsKH = {
	title: "ĐỐI VỚI KHÁCH HÀNG VIP",
	list: [
		{
			title: "PHÂN TÍCH HÌNH ẢNH CÁ NHÂN TRÊN KHÔNG GIAN MẠNG",
			content:
				"Tư vấn & Triển khai chiến lược Marketing Xây dựng & Phát triển cộng đồng trên các kênh Social Media cho các thương hiệu",
		},
		{
			title: "CẢNH BÁO KHỦNG HOẢNG TRUYỀN THỐNG VỚI CÁ NHÂN",
			content:
				"Theo dõi, quản trị hình ảnh cá nhân, phòng chống khủng hoảng Đo lường hiệu quả Marketing Chăm sóc fan hâm mộ, người theo dõi, khách hàng trên MXH (Social Customer Care). Nghiên cứu, thống kê người dùng, thương hiệu, thị trường… ",
		},
	],
};

const vsNN = {
	title: "ĐỐI VỚI CƠ QUAN NHÀ NƯỚC",
	list1: [
		"PHÁT HIỆN CÁC XU HƯỚNG XÃ HỘI NÓNG",
		"THEO DÕI DƯ LUẬN, ĐÁNH GIÁ DƯ LUẬN CHO MỘT CHỦ ĐỀ CỤ THỂ",
	],
	list2: [
		"Thu thập thông tin  thương hiệu, thị trường,  người dùng trên Internet",
		"Phân tích, thống kê xu hướng  và hành vi thương hiệu, người  dùng,…",
		"Tư vấn, chăm sóc, phát triển  thương hiệu, khách hàng tiềm  năng…",
	],
};

const quest = {
	title: "CHÚNG TÔI GIẢI ĐÁP NHỮNG CÂU HỎI CỦA DOANH NGHIỆP",
	list: [
		{
			title: "Nghiên cứu thị trường và đối thủ",
			icon: Icon6,
		},
		{
			title: "Theo dõi phản hồi của công chúng về thương hiệu và sản phẩm",
			icon: Icon5,
		},
		{
			title: "Theo dõi chiến dịch Marketing",
			icon: Icon1,
		},

		{
			title: "Đo lường hiệu quả chiến dịch Marketing",
			icon: Icon2,
		},
		{
			title: "Chăm sóc khách hàng, Trích xuất thông tin",
			icon: Icon3,
		},
		{
			title: "Phòng ngừa và cảnh báo khủng hoảng truyền thông",
			icon: Icon4,
		},
	],
};

const kk = [
	{
		title: "PHÂN TÍCH NGÀNH HÀNG, SO SÁNH CÁC THƯƠNG HIỆU TRONG 1 NGÀNH HÀNG",
		content:
			"Hỗ trợ doanh nghiệp xây dựng cơ sở lên kế hoạch truyền thông hiệu quả thông qua bức tranh toàn cảnh về ngành hàng, thương hiệu và khách hàng: Đánh giá hiệu quả truyền thông của thương hiệu và đối thủ trên thị trường trực tuyến, phân tích phản ứng, phản hồi của người dùng về thương hiệu và đối thủ cạnh tranh, những quan tâm của khách hàng với ngành hàng.",
	},
	{
		title: "PHÂN TÍCH SỨC KHỎE THƯƠNG HIỆU",
		content:
			"Dịch vụ phân tích sức khỏe thương hiệu giúp theo dõi, đo lường, đánh giá và so sánh hiệu quả hoạt động của thương hiệu với các đối thủ trên mạng xã hội. Đồng thời, giúp thương hiệu phát hiện và ngăn chặn kịp thời nguy cơ các thảo luận tiêu cực có khả năng trở thành khủng hoảng truyền thông.",
	},
	{
		title: "PHÂN TÍCH CHIẾN DỊCH MARKETING",
		content:
			"Dịch vụ phân tích chiến dịch Marketing giúp thương hiệu chọn đúng thông điệp, KPIs trước chiến dịch; đo lường liên tục để tối ưu hoá hiệu quả các hoạt động trong chiến dịch; đề xuất cải thiện cho các chiến dịch tiếp theo.",
		content2:
			"Cần theo dõi và phân tích các sắc thái cảm xúc thảo luận của khách hàng (tích cực, tiêu cực, trung lập) để doanh nghiệp có thể hiểu rõ hơn về thái độ của khách hàng với sản phẩm và thương hiệu. Từ đó có thể kịp thời phát hiện các vấn đề cần quan tâm, xử lý. Ngăn chặn kịp thời thảo luận tiêu cực về chiến dịch truyền thông.",
	},
	{
		title: "DỊCH VỤ PHÂN TÍCH XU HƯỚNG MẠNG XÃ HỘI",
		content:
			"Mỗi ngày cần cập nhật đầy đủ thông tin bảng xếp hạng nội dung/ chủ đề nổi bật trên mạng xã hội. Hỗ trợ doanh nghiệp bắt kịp được xu hướng trong việc xây dựng các hoạt động trên thị trường trực tuyến. Từ đó tối ưu hóa hiệu quả truyền thông.",
		list: [
			"Dễ dàng cập nhật chủ đề nóng – mới để hoạt động truyền thông thu hút khán giả",
			"Đào sâu & dự báo cơ hội mới của ngành hàng trước đối thủ. Khi các chủ đề nóng trong ngắn hạn trở thành xu hướng dài hạn",
			"Từ đó, đề xuât chiến thuật xây dựng nội dung, chiến lược mới, sáng tạo và tăng tương tác",
		],
	},
	{
		title: "DỊCH VỤ PHÂN TÍCH XU HƯỚNG NGƯỜI TIÊU DÙNG",
		content: "",
		list: [
			"Hiểu rõ về nhóm khách hàng/ người tiêu dùng đang muốn tiếp cận",
			"Bắt kịp với sự thay đổi của khách hàng để điều chỉnh, cải thiện sản phẩm, dịch vụ và hoạt động truyền thông",
			"Tìm hiểu, phát triển cơ hội kinh doanh mới trong ngành hàng và thương hiệu",
		],
	},
	{
		title: "PHÂN TÍCH KOLS",
		content:
			"Từ việc phân tích KOLS, Thương hiệu sẽ đưa ra được thông điệp, giải pháp truyền thông để tiếp cận khách hàng, cũng như hiệu quả truyền thông lan tỏa tốt nhất. Thế nhưng trong khi chi phí dành cho KOLS ngày càng gia tăng, thương hiệu làm thế nào để sử dụng influencer đúng chuẩn và giảm thiểu tối đa ngân sách?",
		content2:
			"Phân tích KOLS là dịch vụ phân tích chuyên sâu & đánh giá hiệu quả KOLS theo thời gian thực với các chỉ số đo lường khoa học. Từ đó, thương hiệu có thể lựa chọn KOLS phù hợp và tối ưu hóa hiệu quả hoạt động",
	},
	{
		title: "CHĂM SÓC KHÁCH HÀNG NGHIÊN CỨU THỊ TRƯỜNG",
		list: [
			{
				title: "Thu Thập phản hồi tức thì",
				content:
					"Hệ thống thu thập các ý kiến, đánh giá, phản hồi, phàn nàn, góp ý về sản phẩm hay dịch vụ của doanh nghiệp trên các nền tảng mạng xã hội 1 cách nhanh chóng",
			},
			{
				title: "Phân loại phản hồi, theo dõi phản ứng của khách hàng",
				content:
					"Việc phân loại phản hồi giúp doanh nghiệp nắm bắt được nhu cầu của khách hàng, hỗ trợ khách hàng 1 cách tốt nhất. Từ đó nâng cao trải nghiệm của khách hàng đối với sản phẩm và dịch vụ.",
			},
			{
				title: "Hệ thống cảnh báo linh hoạt",
				content:
					"Hệ thống hỗ trợ phân loại phản hồi khách hàng, sau đó gửi cảnh báo về hệ thống CSKH của doanh nghiệp để xử lý kịp thời. ",
			},
			{
				title: "Nghiên cứu thị trường",
				content:
					"Nghiên cứu xu hướng thị trường, đối thủ cạnh tranh và hiệu quả của hoạt động truyền thông thông qua các biểu đồ thống kê trực quan hoặc truy xuất dữ liệu trực tiếp. Từ đó Doanh nghiệp có cơ sở khách quan để lập kế hoạch marketing hiệu quả.",
			},
		],
	},
	{
		title: "CẢNH BÁO KHỦNG HOẢNG TRUYỀN THÔNG",
		list: [
			"Cảnh báo nội dung nhạy cảm",
			"Quản trị và xử lý khủng hoảng số",
			"Báo cáo nhanh phù hợp với từng sự vụ cụ thể",
			"Cung cấp dịch vụ đặc biệt hỗ trợ làm việc  trực tiếp với Facebook, Google, YouTube, KOLs, và phương tiện truyền thông số nhằm giải quyết các trường hợp đặc biệt",
		],
	},
];
const SocialListening = () => {
	return (
		<section className="bg-white">
			<div className="pb-20">
				<div className="w-full bg-orange-500">
					<div className="max-w-c mx-auto">
						<div className="py-32">
							<h1 className="text-6xl text-white font-semibold">
								Social Listening
							</h1>
							<p className="text-2xl text-white mt-5">
								Giải pháp lắng nghe trên mạng xã hội
							</p>
						</div>
					</div>
				</div>
				<div className="mx-auto max-w-c">
					<div className="mt-24">
						<div className="flex justify-start items-center gap-3">
							<div className="py-3 px-4 bg-orange-500">
								<h2 className="text-white text-2xl font-semibold">
									{vsDn.title}
								</h2>
							</div>
						</div>
						<div>
							<ul className="grid grid-cols-2 gap-10 mt-6">
								{vsDn.list.map((item, index) => (
									<li key={index} className="relative">
										<span className="w-10 h-10 leading-10 absolute text-white rounded-full bg-orange-500 top-1/2 -translate-y-1/2 left-0 text-center text-2xl">
											{index + 1}
										</span>
										<p className="pl-14">{item}</p>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="mt-32">
						<div className="flex justify-start items-center gap-3">
							<div className="py-3 px-4 bg-orange-500">
								<h2 className="text-white text-2xl font-semibold">
									{vsKH.title}
								</h2>
							</div>
						</div>
						<div>
							<ul className="grid grid-cols-2 gap-10 mt-6">
								{vsKH.list.map((item, index) => (
									<li
										key={index}
										className="border-2 border-sky-800 p-4 rounded-md bg-white"
									>
										<h1 className="text-2xl text-center text-sky-700 font-semibold">
											{item.title}
										</h1>
										<p className="mt-3">{item.content}</p>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="mt-32">
						<div className="flex justify-start items-center gap-3">
							<div className="py-3 px-4 bg-orange-500">
								<h2 className="text-white text-2xl font-semibold">
									{vsNN.title}
								</h2>
							</div>
						</div>
						<div>
							<ul className="grid grid-cols-2 gap-10 mt-6">
								{vsNN.list1.map((item, index) => (
									<li
										key={index}
										className="border-2 border-sky-800 p-4 rounded-md bg-white"
									>
										<h1 className="text-2xl text-center text-sky-700 font-semibold">
											{item}
										</h1>
									</li>
								))}
							</ul>
							<div className="mt-20">
								<div className="w-3/4 mx-auto flex gap-14 relative z-10">
									{vsNN.list2.map((item, index) => (
										<div key={index} className="bg-white p-3">
											<p className="text-xl text-center">{item}</p>
										</div>
									))}
									<div className="absolute top-1/2 left-0 right-0 w-full h-1 bg-sky-100 z-[-1]"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-32">
						<div className="w-full bg-orange-500 py-3">
							<h2 className="text-2xl text-white text-center font-semibold">
								{quest.title}
							</h2>
						</div>
						<div className="mt-32">
							<ul className="grid grid-cols-3 gap-x-9 gap-y-32">
								{quest.list.map((item, index) => (
									<li
										key={index}
										className="border-2 border-sky-800 p-3 rounded-md min-h-[150px] flex items-end relative"
									>
										<div className="w-32 h-32 border-2 border-sky-800 absolute -top-20 left-10 rounded-md bg-white p-4">
											<img
												src={item.icon.default}
												alt="Logo"
												className="w-full h-full"
											/>
										</div>
										<p className="text-xl">{item.title}</p>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="w-full bg-orange-500 py-3 mt-32">
						<h1 className="text-2xl text-white text-center font-semibold">
							Sản phẩm của chúng tôi cung cấp
						</h1>
					</div>
					<div className="mt-24">
						<div className="flex mx-20 gap-x-32">
							<CircleList index={1} />
							<div className="w-full min-h-[300px] pt-12">
								<div>
									<h2 className="text-3xl font-semibold">{kk[0].title}</h2>
								</div>
								<div className="mt-3">
									<p>{kk[0].content}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-32">
						<div className="flex mx-20 gap-x-32">
							<div className="w-full min-h-[300px] pt-12">
								<div>
									<h2 className="text-3xl font-semibold">{kk[1].title}</h2>
								</div>
								<div className="mt-3">
									<p>{kk[1].content}</p>
								</div>
							</div>
							<CircleList index={2} />
						</div>
					</div>
					<div className="mt-32">
						<div className="flex mx-20 gap-x-32">
							<CircleList index={3} />
							<div className="w-full min-h-[300px] pt-12">
								<div>
									<h2 className="text-3xl font-semibold">{kk[2].title}</h2>
								</div>
								<div className="mt-3">
									<p>{kk[2].content}</p>
								</div>
								<div className="mt-3">
									<p>{kk[2].content2}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-32">
						<div className="flex mx-20 gap-x-32">
							<div className="w-full min-h-[300px] pt-12">
								<div>
									<h2 className="text-3xl font-semibold">{kk[3].title}</h2>
								</div>
								<div className="mt-3">
									<p>{kk[3].content}</p>
								</div>
								<div className="mt-3">
									<ul className="list-disc pl-7">
										{kk[3].list.map((item, index) => (
											<li key={index}>{item}</li>
										))}
									</ul>
								</div>
							</div>
							<CircleList index={4} />
						</div>
					</div>
					<div className="mt-32">
						<div className="flex mx-20 gap-x-32">
							<CircleList index={5} />
							<div className="w-full min-h-[300px] pt-12">
								<div>
									<h2 className="text-3xl font-semibold">{kk[4].title}</h2>
								</div>
								<div className="mt-3">
									<p>{kk[4].content}</p>
								</div>
								<div className="mt-3">
									<ul className="list-disc pl-7">
										{kk[4].list.map((item, index) => (
											<li key={index}>{item}</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-32">
						<div className="flex mx-20 gap-x-32">
							<div className="w-full min-h-[300px] pt-12">
								<div>
									<h2 className="text-3xl font-semibold">{kk[5].title}</h2>
								</div>
								<div className="mt-3">
									<p>{kk[5].content}</p>
								</div>
								<div className="mt-3">
									<p>{kk[5].content2}</p>
								</div>
							</div>
							<CircleList index={6} />
						</div>
					</div>
					<div className="mt-32">
						<div className="flex mx-20 gap-x-32">
							<CircleList index={7} />
							<div className="w-full min-h-[300px] pt-12">
								<div>
									<h2 className="text-3xl font-semibold">{kk[6].title}</h2>
								</div>
								<div className="mt-3">
									<ul className="list-disc pl-7">
										{kk[6].list.map((item, index) => (
											<li key={index}>
												<h1>{item.title}</h1>
												<div>{item.content}</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-32">
						<div className="flex mx-20 gap-x-32">
							<div className="w-full min-h-[300px] pt-12">
								<div>
									<h2 className="text-3xl font-semibold">{kk[7].title}</h2>
								</div>
								<div className="mt-3 list-7">
									<ul className="list-disc pl-7">
										{kk[7].list.map((item, index) => (
											<li key={index}>{item}</li>
										))}
									</ul>
								</div>
							</div>
							<CircleList index={8} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

const CircleList = ({ index }) => (
	<div className="relative pb-3">
		<div className="absolute top-0 left-1/2 right-0 flex flex-col gap-10 items-center z-10">
			<div className="w-32 h-32 text-center leading-[8rem] text-6xl font-bold text-white rounded-full bg-orange-500">
				{index}
			</div>
			<div className="w-16 h-16 text-center  text-6xl font-bold text-white rounded-full bg-orange-500"></div>
			<div className="w-4 h-4 text-center  text-6xl font-bold text-white rounded-full bg-orange-500"></div>
		</div>
		<div className="h-full w-[1px] bg-slate-500 absolute top-0 left-1/2 -translate-x-1/2 z-0"></div>
	</div>
);

export default SocialListening;
